<template>
  <div>
    <CustomTable
      :items="items"
      :fields="fields"
      @handlerSort="handlerSort"
      @handlerDelete="handlerDelete"
    />
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>
<script>
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'

export default {
  mixins: [general],
  data() {
    return {
      fields: [
        { key: 'code', label: 'Mã kệ', sortable: true },
        { key: 'name', label: 'Tên kệ', sortable: true },
        { key: 'storage_id', label: 'Thuộc kho' },
        { key: 'quantity', label: 'Sức chứa' },
        { key: 'location', label: 'Vị trí' },
        { key: 'more', label: 'Tool' },
      ],
      items: [
        {
          id: 1,
          code: 'K01-01',
          name: 'Kệ ngang',
          is_ware: 'Kho 01',
          quantity: '258/300',
          place: 'Gốc trái trong cùng',
        },
        {
          id: 5,
          code: 'K01-02',
          name: 'Kệ ngang',
          is_ware: 'Kho 01',
          quantity: '250/300',
          place: 'Gốc trái trong cùng, cuối hàng',
        },
      ],
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    async handlerSort(data) {
      const { item } = data
      const { method } = data
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type: 1,
          ids: [],
        }
        if (method === 'up') {
          params.ids = [item.item.id, this.items[item.index - 1].id]
        }
        if (method === 'down') {
          params.ids = [item.item.id, this.items[item.index + 1].id]
        }
        if (method === 'change') {
          params.type = 2
          params.ids = [
            {
              id: item.item.id,
              position: Number(item.item.position),
            },
          ]
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/supplier/change_position`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id_number
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/store_shelves?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = await this.getDataTranslation(
              res.data.data.items,
              true,
            )
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/store_shelve/${id}?site_id=${siteId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your shelf has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>
